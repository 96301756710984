body {
    font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
  background: linear-gradient(rgb(248, 248, 255), rgb(48, 62, 143));
  position: relative;
}

/* navigation */

nav {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    background-color: #2F4F4F;
    font-family: 'Poppins', sans-serif;
    padding: 0 10px;
    width: 100%;
    z-index: 2;
}

.logo-link {
    text-decoration: none;
}

.logo {
    color: #FFFAF0;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
}

.logo img {
    height: 54px;
    margin-top: 3px;
}

.logo h1 {
    font-size: 14px;
}

.logo-wrap {
    height: 58px;
    width: 58px;
    background: rgb(250,250,250);
    background: radial-gradient(circle, rgba(250,250,250,0.9904778317577031) 0%, rgba(255,255,255,0) 75%);
}

.nav-links {
    position: absolute;
    right: 0;
    height: 92vh;
    top: 65px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 70%;
    background-color: #2F4F4F;
    transform: translateX(100%);
    transition: transform 0.5s ease;
    z-index: 2;
    padding-bottom: 20vh;
}

.nav-links li {
    position: relative;
    list-style: none;
    opacity: 0;
    text-align: center;
}

.nav-links a {
    color: #FFFAF0;
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 14px;
}

.nav-links a,
.nav-links a:after,
.nav-links a:before {
    transition: all 0.5s;
    position: relative;
}

.nav-links a:hover {
    color: #ffffff;
}

.nav-links a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
}

.nav-links a:hover:after {
    width: 100%;
}

.social-links {
    position: absolute;
    display: flex;
    align-items: center;
    padding-top: 5px;
    right: 0;
    bottom: -87vh;
    justify-content: space-around;
    transform: translateX(100%);
    transition: transform 0.5s ease;
    z-index: 3;
    width: 70%;
}

.social-links a {
    padding-left: 6px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    filter: drop-shadow(1px 2px 2px #000000);
}

.social-links a:hover {
    filter: drop-shadow(0px 0px 0px #000000);
}

.sub-menu {
    padding-top: 15px;
    text-align: center;
    display: block;
}

.sub-menu li {
    color: white;
    opacity: 1;
}

.burger {
    display: block;
    cursor: pointer;
}

.burger div {
    width: 25px;
    height: 2px;
    background-color: #FFFAF0;
    margin: 5px 5px 4px 5px;
    transition: all 0.3s ease;
}

.toggle .bar-1 {
    transform: rotate(-45deg) translate(-5px,6px);
}

.toggle .bar-2 {
    opacity: 0;
}

.toggle .bar-3 {
    transform: rotate(45deg) translate(-4px,-5px);
}

.nav-active {
    transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@media (min-width: 522px) {
    .nav-links {
        width: 45%;
    }
    .social-links {
        width: 45%;
    }
}


.App-header {
  background-color: #282c34;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 85px 20px 20px 20px;
  cursor: pointer;
}

.spinner {
  height: 80px;
  margin: auto;
}

.App-intro {
  font-size: large;
}

/* new css for movie component */

* {
  box-sizing: border-box;
}

.movies {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
}

h2 {
    white-space: pre-wrap;
}

p {
    margin-top: .05em;
    margin-bottom: 0;
}

.App-header h2 {
  margin: 0;
}

.add-movies {
  text-align: center;
}

.add-movies button {
  font-size: 16px;
  padding: 8px;
  margin: 0 10px 30px 10px;
}

.movie {
  padding: 5px 25px 10px 25px;
  max-width: 25%;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.movie-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.movie-links a {
    transition: all 0.3s ease-in-out;
    margin: 5px 0;
    padding: 0.5em 1.5em;
    background: #093341;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1.5px;
    text-decoration: none;
    text-transform: uppercase;
}
.movie-links a:hover {
    background: #0076a3;
}

.errorMessage {
  margin: auto;
  font-weight: bold;
  color: rgb(161, 15, 15);
}

.search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

input[type="submit"] {
  padding: 5px;
  background-color: #093341;
  color: white;
  border: 1px solid #0076a3;
  border-radius: 3px;
  width: 80px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 0.875rem;
    font-weight: 600;
}

input[type="submit"]:hover {
  background-color: #0076a3;
  color: antiquewhite;
}

.search > input[type="text"]{
  width: 40%;
  min-width: 170px;
}

@media (min-width: 768px) {
    .App-header {
        padding-top: 20px;
    }
    nav {
        position: relative;
        justify-content: space-around;
        padding: 0;
    }
    .logo h1 {
        font-size: 16px;
    }
    .nav-links {
        position: relative;
        right: unset;
        height: unset;
        top: unset;
        align-items: unset;
        display: flex;
        flex-direction: row;
        width: 50%;
        display: flex;
        justify-content: space-between;
        transform: translateX(0%);
        padding-bottom: 0;
        padding-left: 20px;
    }
    .nav-links li {
        opacity: 1;
    }
    .nav-links li:hover > .sub-menu {
        display: block;
    }
    .drop-down:focus + .sub-menu {
        display: block;
    }
    .social-links {
        position: relative;
        padding-top: 5px;
        right: unset;
        bottom: unset;
        justify-content: unset;
        width: initial;
        transform: translateX(0%);
    }
    .social-links a {
        opacity: 1;
    }
    .burger {
        display: none;
    }
    .sub-menu {
        display: none;
        position: absolute;
        top: 0;
        left: -10px;
        background-color: #2F4F4F;
        width: auto;
        min-width: 255px;
        padding: 40px 10px 10px 10px;
        z-index: -1;
    }
    .sub-menu li {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .logo h1 {
        font-size: 18px;
    }
    .nav-links {
        padding-left: 0;
    }
}

@media (min-width: 1100px) {
    .nav-links {
        width: 30%;
    }
}
@media screen and (min-width: 694px) and (max-width: 915px) {
  .movie {
    max-width: 33%;
  }
}

@media screen and (min-width: 652px) and (max-width: 693px) {
  .movie {
    max-width: 50%;
  }
}

@media screen and (max-width: 651px) {
  .movie {
    max-width: 100%;
    margin: auto;
  }
}